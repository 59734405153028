<template>
    <div class="container mx-auto text-center p-6">
      <!-- Logo -->
      <img src="@/assets/radiospotter-logo.png" alt="Radiospotter Logo" class="mx-auto mb-8 max-w-xs" style="max-width: 250px;" />
  
      <!-- Headline -->
      <h1 class="text-2xl font-bold mb-4">Radiospotter ist 2024 wieder zurück</h1>
  
      <!-- Description -->
      <p class="text-secondary mb-6">Melde dich bis 24.12.2023 an und erhalte einen Rabatt im nächsten Jahr.</p>
  
      <!-- Sign Up Form Component -->
      <SignUpForm />
    </div>
  </template>
  
  
  <script>
  import SignUpForm from './SignUpForm.vue';
  
  export default {
    components: {
      SignUpForm
    }
  }
  </script>
  