<template>
    <form @submit.prevent="submitEmail" class="flex flex-col items-center">
      <!-- Email Input -->
      <input v-model="email" required type="email" placeholder="Ihre E-Mail Adresse" class="p-2 border rounded-xl shadow-smooth transition-spacing duration-300 focus:shadow-outline" style="min-width: 250px;"/>
      
      <!-- Submit Button -->
      <button type="submit" class="bg-primary text-white px-4 py-2 rounded-xl mt-2 hover:bg-primary-dark shadow-smooth transition-colors duration-300">
        Anmelden
      </button>
    </form>
  </template>
  
  <script>
    import Airtable from 'airtable';

  export default {
    data() {
      return {
        email: ''
      };
    },

        methods: {
        async submitEmail() {
            const base = new Airtable({ apiKey: process.env.VUE_APP_AIRTABLE_API_KEY }).base(process.env.VUE_APP_AIRTABLE_BASE_ID);

            try {
                console.log("submitting mail")
            await base(process.env.VUE_APP_AIRTABLE_TABLE_NAME).create([
                { fields: { 'Email': this.email } },
            ]);

            alert('E-Mail erfolgreich eingetragen!');
            } catch (error) {
            console.error('Error:', error);
            alert('Fehler beim Eintragen der E-Mail.');
            }
        }
        }

    
  }
  </script>
  